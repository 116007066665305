.body {
  position: absolute;
  height: 100%;
  width: 100%;
  min-height: 768px;
}

.with-left-margin {
  width: calc(100% - 360px);
  margin-left: 360px;
  animation: openLeftPanel 200ms cubic-bezier(0.4, 1, 0.75, 0.9) forwards;
}

.with-right-margin {
  width: calc(100% - 360px);
  margin-right: 360px;
  animation: openRightPanel 5ms cubic-bezier(0.4, 1, 0.75, 0.9) forwards;
}

@keyframes openLeftPanel {
  0% {
    margin-left: 0px;
    width: 100%;
  }
  100% {
    margin-left: 360px;
    width: calc(100% - 360px);
  }
}

/* @keyframes closeLeftPanel {
  0% {
    margin-left: 360px;
    width: calc(100% - 360px);
  }
  100% {
    margin-left: 0px;
    width: 100%;
  }
} */

@keyframes openRightPanel {
  0% {
    margin-right: 0px;
    width: 100%;
  }
  100% {
    margin-right: 360px;
    width: calc(100% - 360px);
  }
}

/* @keyframes closeRightPanel {
  0% {
    margin-right: 360px;
    width: calc(100% - 360px);
  }
  100% {
    margin-right: 0px;
    width: 100%;
  }
} */

.navbar {
  height: 50px;
  width: 100%;
  -moz-box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15);
  -webkit-box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15);
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15);
}

.home-container {
  height: calc(100% - 150px);
  max-width: 720px;
  margin: 100px auto 0 auto;
}

.home-container .heading-container {
  padding: 20px;
  text-align: center;
}

.home-container .card-container .link {
  border: 0;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  background-color: white;
}

.home-container .card-container .row {
  width: 100%;
}

.home-container .card-container .column-6-12 {
  width: calc(50% - 10px);
  float: left;
  margin: 5px;
}

.home-container .card-container .column-12-12 {
  width: calc(100% - 10px);
  float: left;
  margin: 5px;
}

.module-links-container {
  display: flex;
  float: left;
  height: 50px;
  width: calc(100% - 360px);
}

.module-links-container .bp3-navbar-group {
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
}

.module-links-container .bp3-overflow-list {
  width: -moz-available;
  width: -webkit-fill-available;
}

.module-links-container button {
  border: 0;
  border-radius: 3px;
  padding: 0;
  margin: 0 5px;
  background-color: white;
}

.drawer-container > .bp3-overlay {
  height: 100%;
  width: 360px;
  min-height: 768px;
}

.tutorial-drawer-container > .bp3-overlay {
  min-height: 768px;
}

.tutorial-drawer-container .bp3-overlay-backdrop {
  background-color: rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 0 5px #0E5A8A;
}

.tutorial-drawer-container.active .bp3-overlay-backdrop {
  box-shadow: inset 0 0 20px #0E5A8A;
}

.tutorial-drawer-container.active.finished .bp3-overlay-backdrop {
  box-shadow: inset 0 0 25px #0A6640;
}

.tutorial-drawer-container .bp3-drawer {
  box-shadow: 0 0 5px #0E5A8A;
}

.tutorial-drawer-container.active .bp3-drawer {
  box-shadow: 0 0 20px #0E5A8A;
}

.tutorial-drawer-container.active.finished .bp3-drawer {
  box-shadow: 0 0 25px #0A6640;
}

.plot-controls {
  height: 40%;
  width: 50%;
  float: left;
  padding: 10px;
}

.plot-controls .inner {
  height: 100%;
  overflow-y: scroll;
}

.plot-container {
  height: 60%;
  width: 100%;
  float: left;
  padding: 10px;
}

.bp3-select-popover {
  max-height: 400px;
  overflow-y: scroll;
}

.bp3-multi-select-popover {
  max-height: 200px;
  overflow-y: scroll;
}

.bp3-label.with-info-popover {
  margin-bottom: 10px;
}

.bp3-label.with-info-popover .bp3-popover-wrapper {
  display: inline-flex;
}

.bp3-label.with-info-popover > b {
  font-weight: 500;
}

.info-container {
  max-width: 400px;
}

.info-container .bp3-callout {
  margin: 0;
  overflow: scroll;
}

.info-container .bp3-callout .callout-body {
  max-height: 110px;
  overflow: scroll;
}
