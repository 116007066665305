.published-cohort {
  height: 72px;
}

.published-cohort .element {
  height: 100%;
  margin: 5px;
  padding: 5px;
  border-radius: 3px;
  background-color: #CED9E0;
}

.published-cohort .element.selected {
  background-color: #3DCC91;
}

.published-cohort .element .buttons {
  position: relative;
  top: 0;
  left: 0;
  width: 24px;
  float: left;
}

.published-cohort .element .cohort-body {
  width: calc(100% - 48px);
  float: left;
  padding: 0 5px;
}

.published-cohort .element .row.title {
  height: 24px;
  width: 100%;
  padding: 4px 0;
  font-weight: bold;
}

.published-cohort .row.title .icons {
  position: relative;
  left: calc(100% - 32px);
}

.published-cohort .row.title .icons .bp3-icon:first-child {
  margin-right: 4px;
}

.cohorts-container .counts-one-container {
  height: 100%;
  width: 60%;
  float: left;
}

.cohorts-container .id-count.bp3-tag {
  width: calc(50% - 4px);
  float: left;
  margin: 0 4px 4px 0px;
}

.cohorts-container .progress-bar-container {
  height: 100%;
  width: 40%;
  float: left;
}

.element .bp3-progress-bar {
  margin: 4px 0;
}

.cohorts-container .bp3-tag {
  display: flex;
  width: fit-content;
  float: left;
}

.mapping-popover-container .bp3-popover-wrapper {
  height: 30px;
  width: 100%;
}

.mapping-popover-container .bp3-overlay {
  height: 30px;
  width: 0;
}

.sample-mapping-popover {
  width: 300px;
  padding: 10px;
}

.mapping-popover-container .bp3-label {
  text-align: left;
}

.highlighted-published-cohort-dialog .heading-container > * {
  margin-bottom: 10px;
}

.highlighted-published-cohort-dialog .authors-container {
  height: 18px;
  width: 100%;
}

.highlighted-published-cohort-dialog .authors-container > .first-authors {
  float: left;
  width: 70%;
}

.highlighted-published-cohort-dialog .authors-container > .last-authors {
  float: right;
  width: fit-content;
}

.highlighted-published-cohort-dialog .abstract-container {
  text-align: justify;
}

.highlighted-published-cohort-dialog .link-container {}

.highlighted-published-cohort-dialog .cancer-type-container > button {
  margin: 0 4px 4px 0;
}


/* .highlighted-published-cohort-dialog .fields-container {
  height: 190px;
  overflow-y: scroll;
  margin: 10px 0;
}

.highlighted-published-cohort-dialog .slider-container {
  padding-right: 60px;
}

.highlighted-published-cohort-dialog .slider-container .bp3-slider {
  height: 20px;
}

.highlighted-published-cohort-dialog .slider-container .bp3-slider-track {
  right: 4px;
}

.highlighted-published-cohort-dialog .slider-container .bp3-slider-axis .bp3-slider-label:first-child {
  display: none;
}

.highlighted-published-cohort-dialog .slider-container .bp3-slider-handle {
  width: 0;
  box-shadow: none;
}

.highlighted-published-cohort-dialog .slider-container .bp3-slider-axis .bp3-slider-label:last-child {
  transform: translate(-2px, 0px);
  -webkit-transform: translate(-2px, 0px);
  box-shadow: none;
}

.highlighted-published-cohort-dialog .slider-container .bp3-slider-handle .bp3-slider-label {
  transform: translate(-6px, 0px);
  -webkit-transform: translate(-6px, 0px);
  box-shadow: none;
} */
